import DateFnsUtils from '@date-io/date-fns';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import { DateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { useOktaAuth } from '@okta/okta-react';
import ModalDialog from 'components/ModalDialog';
import RayButton from 'components/RayButton';
import RayInput from 'components/RayInput';
import BodyText from 'components/Typography/Body';
import { H2, H5 } from 'components/Typography/Heading';
import { format } from 'date-fns';
import React, { useState } from 'react';
import { FiDownload } from 'react-icons/fi';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import axios, { isCustomAxiosError } from 'utils/axios';
import { stringifyPrice } from 'utils/functions/stringifyPrice';
import { useAuthContext } from 'utils/hooks/useAuthContext';
import pdfIcon from '../../assets/pdf_icon.png';
import { VirtualOfficeDocument } from '../VirtualOfficePage/types';
import UpdateDocumentStatus from './UpdateDocumentStatus';
import { PackageObject, PaymentObject, SubscriptionObject } from './types';
import fetchFileURL, {
  AttachmentItemType,
  attachments,
  getFileName,
  slugify,
} from './util';
import DirectorsTable from './DirectorsTable/DirectorsTable';
import { ChangeBuildingModal } from './ChangeBuildingModal';
import { ChangeCompanyNameModal } from './ChangeCompanyNameModal';
import { Button } from '@material-ui/core';

const InvoiceWrapper = styled.div`
  a {
    display: flex;
    align-items: center;
  }
`;

type Props = {
  documentData: VirtualOfficeDocument;
  payments: PaymentObject[];
  subscriptions: SubscriptionObject[];
  packages: PackageObject[];
  deregistration?: any;
  fetchDocument: () => void;
};

function Details({
  documentData,
  payments,
  subscriptions,
  packages,
  fetchDocument,
  deregistration,
}: Props) {
  const { setToastType, userInfo } = useAuthContext()!;
  const { push } = useHistory();
  const { authState } = useOktaAuth();
  const [changeStatusModal, toggleChangeStatusModal] = useState<boolean>(false);
  const [openChangeBuildingModal, setOpenChangeBuildingModal] = useState<
    boolean
  >(false);
  const [openChangeCompanyNameModal, setOpenChangeCompanyNameModal] = useState<
    boolean
  >(false);
  const [loader, setLoader] = useState<boolean>(false);
  const [detailModal, toggleDetailModal] = useState<boolean>(false);
  const [packageNotes, setPackageNotes] = useState<string>('');
  const [packageTitle, setPackageTitle] = useState<string>('');
  const [packageTrackingID, setPackageTrackingID] = useState<string>('');
  const [packageDate, setPackageDate] = useState<any>(new Date());
  const [expiryDate, setExpiryDate] = useState<any>(new Date());
  const [refreshAddressLoader, setRefreshAddressLoader] = useState<boolean>(
    false,
  );
  const [concludeMembershipLoader, setConcludeMemberShipLoader] = useState<
    boolean
  >(false);
  const [approve, setApprove] = useState<boolean>(true);
  const [reject, setReject] = useState<boolean>(false);
  const [loading, setLoading] = useState(false);

  React.useEffect(() => {
    const { kycGracePeriodExpiryTimestamp } = documentData;
    if (
      documentData &&
      kycGracePeriodExpiryTimestamp &&
      kycGracePeriodExpiryTimestamp._seconds
    ) {
      setExpiryDate(new Date(kycGracePeriodExpiryTimestamp._seconds * 1000));
    }
  }, [documentData]);

  const approveDocument = async () => {
    setLoader(true);
    try {
      await axios.patch(
        documentData.unIncorporatedRegistration
          ? `/vo/admin/approve-manual?docId=${documentData.id}`
          : `/vo/admin/approve?docId=${documentData.id}`,
        {},
        { headers: { Authorization: authState?.accessToken?.accessToken } },
      );
      setToastType({
        type: 'success',
        message: 'Document Approved',
        show: true,
      });
      setLoader(false);
      push('/vo-bookings');
    } catch (err) {
      const error: any = err;
      console.error(error);

      setToastType({
        show: true,
        type: 'error',
        message:
          error &&
          error.response &&
          error.response.data &&
          error.response.data.message
            ? error.response.data.message
            : 'Error occurred while approving the Document',
      });
      setLoader(false);
    }
  };
  const sendPackage = async () => {
    setLoader(true);
    const body = {
      doc_id: documentData.id,
      title: packageTitle,
      trackingID: packageTrackingID,
      notes: packageNotes,
      receivedAt: packageDate,
    };
    try {
      await axios.post(`/vo/admin/send-package`, body, {
        headers: {
          Authorization: authState?.accessToken?.accessToken,
        },
      });
      setToastType({
        type: 'success',
        message: 'Package sent successfully',
        show: true,
      });
      setLoader(false);
      toggleDetailModal(false);
      // Re-fetch the document details
      fetchDocument();
    } catch (err) {
      const error: any = err;
      console.error(error);
      setToastType({
        show: true,
        type: 'error',
        message:
          error &&
          error.response &&
          error.response.data &&
          error.response.data.message
            ? error.response.data.message
            : 'Error occurred while sending the Package',
      });
      setLoader(false);
    }
  };
  const handleDateChange = (date: any) => {
    if (date) setPackageDate(date);
  };
  const handleExpiryDate = (date: any) => {
    if (date) setExpiryDate(date);
  };

  const handleApprove = (approve: boolean) => {
    if (approve) setApprove(approve);
    updateDeregistrationRequest(true);
  };

  const handleReject = (reject: boolean) => {
    if (reject) setReject(reject);
    updateDeregistrationRequest(false);
  };
  const updateGracePeriod = async () => {
    setLoader(true);
    try {
      await axios.patch(
        '/vo/admin/update-grace-period',
        { doc_id: documentData.id, grace_period: expiryDate },
        { headers: { Authorization: authState?.accessToken?.accessToken } },
      );
      setToastType({
        type: 'success',
        message: 'Grace Period Updated Successfully',
        show: true,
      });
      setLoader(false);
    } catch (err) {
      const error: any = err;
      console.error(error);
      setToastType({
        show: true,
        type: 'error',
        message:
          error &&
          error.response &&
          error.response.data &&
          error.response.data.message
            ? error.response.data.message
            : 'Error occurred while updating date for Grace Period',
      });
      setLoader(false);
    }
  };

  const concludeMemberShip = async () => {
    setConcludeMemberShipLoader(true);
    try {
      await axios.patch(
        '/vo/document/conclude-end-membership',
        { voDocId: documentData.id },
        { headers: { Authorization: authState?.accessToken?.accessToken } },
      );
      setToastType({
        type: 'success',
        message: 'Membership has been concluded',
        show: true,
      });
      setConcludeMemberShipLoader(false);
    } catch (err) {
      const error: any = err;
      setToastType({
        show: true,
        type: 'error',
        message:
          error &&
          error.response &&
          error.response.data &&
          error.response.data.message
            ? error.response.data.message
            : 'Error occurred while updating date for Grace Period',
      });
      setConcludeMemberShipLoader(false);
    }
  };

  const refreshAddress = async () => {
    setRefreshAddressLoader(true);
    try {
      await axios.patch(
        '/vo/document/refresh-address-manual',
        { voDocId: documentData.id },
        { headers: { Authorization: authState?.accessToken?.accessToken } },
      );
      setToastType({
        type: 'success',
        message: 'Refreshed Address',
        show: true,
      });
      setRefreshAddressLoader(false);
    } catch (err) {
      const error: any = err;
      setToastType({
        show: true,
        type: 'error',
        message:
          error &&
          error.response &&
          error.response.data &&
          error.response.data.message
            ? error.response.data.message
            : 'Error occurred while updating date for Grace Period',
      });
      setRefreshAddressLoader(false);
    }
  };

  const updateDeregistrationRequest = async (expiryValue: boolean) => {
    try {
      const response = await axios.patch(
        `/vo/deregister`,
        {
          expiryValue: expiryValue,
          voDocId: documentData.id,
        },
        { headers: { Authorization: authState?.accessToken?.accessToken } },
      );
      if (response.status === 200) {
        setToastType({
          type: 'success',
          message: expiryValue
            ? 'Approved the end membership request'
            : 'Declined end membership request',
          show: true,
        });
        setLoader(false);
        push('/vo-bookings');
      }
    } catch (error) {
      console.log(error);
      setToastType({
        show: true,
        type: 'error',
        message: expiryValue
          ? 'Error occurred while approving end membership request'
          : 'Error occurred while declining end membership request',
      });
      setLoader(false);
      push('/vo-bookings');
    }
  };

  const downloadLink = (fileName: string) => {
    const anchor = document.createElement('a');
    anchor.setAttribute('href', fileName);
    anchor.target = '_blank';
    anchor.download = 'true';
    anchor.click();
  };

  const downloadFile = async (attachment: AttachmentItemType) => {
    const { type, parentFolder, value } = attachment;

    if (
      (parentFolder === 'virtual-offices' &&
        value === 'NOC' &&
        // To check if the user has leegalityMembershipResponse which will indicate that the user has signed document using leegality
        !!documentData.leegalityMembershipResponse &&
        (!documentData.leegalityNOCResponse ||
          documentData.leegalityNOCResponse?.inviteeInfo?.status ===
            'PENDING')) ||
      (documentData.status === 'DOCUSIGN_EMAIL_SENT' && value === 'NOC')
    ) {
      setToastType({
        show: true,
        type: 'error',
        message:
          'Leegality agreement not yet created / updated for the new subscription.',
      });
      return;
    }

    if (
      parentFolder === 'virtual-offices' &&
      value === 'MSA' &&
      documentData.leegalityMembershipResponse?.inviteeInfo?.status ===
        'PENDING'
    ) {
      setToastType({
        show: true,
        type: 'error',
        message: 'User has not signed the membership agreement yet',
      });
      return;
    }

    try {
      const token = authState?.accessToken?.accessToken;
      const fileName = getFileName(value, parentFolder, type, documentData);
      // New flow if name has http then just display it
      if (fileName.startsWith('http')) {
        downloadLink(fileName);
        return;
      }
      // else go here
      const response = await fetchFileURL(
        parentFolder,
        fileName,
        token as string,
      );
      if (!response?.valid) {
        setToastType({
          show: true,
          type: 'error',
          message: response?.message as string,
        });
        return;
      }
      downloadLink(response.fileUrl);
    } catch (error) {
      console.error(error);
      if (isCustomAxiosError(error)) {
        console.error(error);
        setToastType({
          show: true,
          type: 'error',
          message:
            error?.response?.data?.message ||
            'Error occurred while downloading the document',
        });
      }
    }
  };

  const downloadImage = async () => {
    setLoading(true);

    try {
      // API call to get the image URL if company logo is available.
      const response = await axios.get(
        `/nest-api/virtual-offices-company-logo/${documentData.companyInfo?.logo_id}/presigned-url`,
        {
          headers: {
            Authorization: `${authState?.accessToken?.accessToken}`,
          },
        },
      );
      const imageUrl = response.data?.data?.pre_signed_url; // Assuming the URL is in the response

      // Create an anchor element and trigger download
      const link = document.createElement('a');
      link.href = imageUrl;
      link.target = '_blank';
      link.download = documentData.companyInfo?.name + ' logo';
      document.body.appendChild(link);
      link.click();

      // Clean up by removing the anchor element after download
      document.body.removeChild(link);
    } catch (error) {
      console.error('Error downloading image:', error);
      if (isCustomAxiosError(error)) {
        setToastType({
          show: true,
          type: 'error',
          message:
            error?.response?.data?.message ||
            'Error occurred while downloading the logo',
        });
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <RayButton
                buttonType="secondary"
                onClick={() => push('/vo-bookings')}
              >
                Back
              </RayButton>
            </Grid>
            <Grid item xs={4}>
              <H2>VO Document Details</H2>
            </Grid>
            {documentData.status === 'COMPLETED' &&
              documentData.membershipEnded && (
                <Grid item xs={2}>
                  <RayButton fullWidth onClick={() => refreshAddress()}>
                    {!refreshAddressLoader ? (
                      'Refresh Address'
                    ) : (
                      <CircularProgress size={15} style={{ color: '#fff' }} />
                    )}
                  </RayButton>
                </Grid>
              )}
            {documentData.status === 'COMPLETED' &&
              documentData.membershipEnded && (
                <Grid item xs={2}>
                  <RayButton
                    disabled={documentData.endMembershipConcluded}
                    fullWidth
                    onClick={() => concludeMemberShip()}
                  >
                    {!concludeMembershipLoader ? (
                      'Conclude Membership'
                    ) : (
                      <CircularProgress size={15} style={{ color: '#fff' }} />
                    )}
                  </RayButton>
                </Grid>
              )}
            {!documentData.isVoMultiAccount &&
              documentData.latestSubscriptionId === 'subscription1' && (
                <Grid item xs={2}>
                  <RayButton
                    disabled={loader}
                    fullWidth
                    onClick={() => toggleChangeStatusModal(true)}
                  >
                    Change Status
                  </RayButton>
                </Grid>
              )}
            {userInfo.is_super_admin && (
              <Grid item xs={2}>
                <RayButton
                  disabled={loader}
                  fullWidth
                  onClick={() => setOpenChangeBuildingModal(true)}
                >
                  Change Building
                </RayButton>
              </Grid>
            )}
            {userInfo.is_super_admin && (
              <Grid item xs={2}>
                <RayButton
                  disabled={loader}
                  fullWidth
                  onClick={() => setOpenChangeCompanyNameModal(true)}
                >
                  Edit Company Name
                </RayButton>
              </Grid>
            )}
            {((documentData.unIncorporatedRegistration &&
              documentData.kycAttempted) ||
              !documentData.unIncorporatedRegistration) && (
              <Grid item xs={2}>
                <RayButton
                  disabled={documentData.totalKycComplete || loader}
                  fullWidth
                  onClick={approveDocument}
                >
                  {!loader ? (
                    'Approve'
                  ) : (
                    <CircularProgress size={15} style={{ color: '#fff' }} />
                  )}
                </RayButton>
              </Grid>
            )}
          </Grid>
        </Grid>
        <Grid item xs={4}>
          <H5>Building Address</H5>
          <BodyText mb0>
            {documentData.building.virtualOfficeContractAddress}
          </BodyText>
        </Grid>
        <Grid item xs={3}>
          <H5>Created At</H5>
          <BodyText mb0>
            {format(
              new Date(documentData.createdAt),
              'do MMM yyyy, hh:mm aaaa',
            )}
          </BodyText>
        </Grid>
        <Grid item xs={3}>
          <H5>Updated At</H5>
          <BodyText mb0>
            {format(
              new Date(documentData.updatedAt),
              'do MMM yyyy, hh:mm aaaa',
            )}
          </BodyText>
        </Grid>
        <Grid item xs={2}>
          <H5>Primary User</H5>
          <BodyText mb0>
            {documentData.primaryUser ? documentData.primaryUser : 'NA'}
          </BodyText>
        </Grid>
        <Grid item xs={2}>
          <H5>Desk ID</H5>
          <BodyText mb0>
            {documentData.deskId ? documentData.deskId : '--'}
          </BodyText>
        </Grid>
        <Grid item xs={2}>
          <H5>Unincorporated Registration</H5>
          <BodyText mb0>
            {documentData.unIncorporatedRegistration ? 'YES' : 'NO'}
          </BodyText>
        </Grid>
        <Grid item xs={3}>
          <H5>All Directors Verified</H5>
          <BodyText mb0>
            {documentData.allDirectorsVerified ? 'YES' : 'NO'}
          </BodyText>
        </Grid>
        <Grid item xs={3}>
          <H5>VO Document Approved</H5>
          <BodyText mb0>
            {documentData.totalKycComplete ? 'YES' : 'NO'}
          </BodyText>
        </Grid>
        <Grid item xs={2}>
          <H5>Payment Complete</H5>
          <BodyText mb0>
            {documentData.status &&
            documentData.status !== 'ADDED_BUILDING_ID' &&
            documentData.status !== 'GENERATED_PAYMENT_LINK_FOR_PLAN'
              ? 'YES'
              : 'NO'}
          </BodyText>
        </Grid>
        <Grid item xs={documentData.kycAttempted ? 2 : 4}>
          <H5>Plan Details</H5>
          {documentData.selectedPlan && !documentData.selectedPlan.title && (
            <BodyText mb0>Not Available</BodyText>
          )}
          {documentData.selectedPlan && documentData.selectedPlan.title && (
            <BodyText
              mb0
            >{`Title: ${documentData.selectedPlan.title}`}</BodyText>
          )}
          <br />
          {documentData.selectedPlan && documentData.selectedPlan.price && (
            <BodyText mb0>{`Price: ${stringifyPrice(
              +documentData.selectedPlan.price,
            )}`}</BodyText>
          )}
          <br />
          {documentData.startingOn && (
            <BodyText mb0>{`Starts On: ${format(
              new Date(documentData.startingOn),
              'do MMM yyyy',
            )}`}</BodyText>
          )}
          <br />
          {documentData.expiresOn && (
            <BodyText mb0>{`Expires On: ${format(
              new Date(documentData.expiresOn),
              'do MMM yyyy',
            )}`}</BodyText>
          )}
        </Grid>
        {documentData.companyInfo && documentData.kycAttempted && (
          <Grid item xs={2}>
            <H5>KYC Attempted</H5>
            <BodyText mb0>YES</BodyText>
          </Grid>
        )}

        <Grid item xs={4}>
          <H5>Docusign Details</H5>
          {(!documentData.docuSignEnevelope ||
            !documentData.docuSignEnevelope.id) && (
            <BodyText mb0>Not Available</BodyText>
          )}
          {documentData.docuSignEnevelope &&
            documentData.docuSignEnevelope.id && (
              <BodyText
                mb0
              >{`ID: ${documentData.docuSignEnevelope.id}`}</BodyText>
            )}
          <br />
          {documentData.docuSignEnevelope &&
            documentData.docuSignEnevelope.verified && (
              <BodyText mb0>
                {`Verified: ${
                  documentData.docuSignEnevelope.verified ? 'YES' : 'NO'
                }`}
              </BodyText>
            )}
        </Grid>
        <Grid item xs={4}>
          {(userInfo.is_super_admin ||
            userInfo.vo_admin ||
            userInfo.isPartnerAdminUser) && (
            <>
              <H5>Company Details</H5>
              {!documentData.companyInfo && (
                <BodyText mb0>Not Available</BodyText>
              )}
              {documentData.companyInfo && documentData.companyInfo.name && (
                <>
                  <BodyText
                    mb0
                  >{`Name: ${documentData.companyInfo.name}`}</BodyText>
                  <br />
                </>
              )}
              {documentData.companyInfo &&
                documentData.companyInfo.proposedCompanyName && (
                  <>
                    <BodyText
                      mb0
                    >{`Proposed Name: ${documentData.companyInfo.proposedCompanyName}`}</BodyText>
                    <br />
                  </>
                )}
              {documentData.companyInfo && documentData.companyInfo.pan && (
                <>
                  <BodyText
                    mb0
                  >{`PAN: ${documentData.companyInfo.pan}`}</BodyText>
                  <br />
                </>
              )}
              {documentData.companyInfo && documentData.companyInfo.tan && (
                <>
                  <BodyText
                    mb0
                  >{`TAN: ${documentData.companyInfo.tan}`}</BodyText>
                  <br />
                </>
              )}
              {documentData.companyInfo &&
                documentData.companyInfo.companyType && (
                  <>
                    <BodyText
                      mb0
                    >{`Type: ${documentData.companyInfo.companyType}`}</BodyText>
                    <br />
                  </>
                )}
              {documentData.companyInfo && documentData.companyInfo.gstin && (
                <BodyText
                  mb0
                >{`GSTIN: ${documentData.companyInfo.gstin}`}</BodyText>
              )}
              {documentData.companyInfo && documentData.companyInfo?.logo_id && (
                <div>
                  <Button
                    variant="contained"
                    startIcon={<FiDownload />}
                    onClick={downloadImage}
                    disabled={loading}
                  >
                    Download logo
                  </Button>
                </div>
              )}
            </>
          )}
        </Grid>
        <Grid item xs={4}>
          <H5>Company Type</H5>
          {!documentData.companyType && <BodyText mb0>Not Available</BodyText>}
          {documentData.companyType && (
            <BodyText mb0>{documentData.companyType}</BodyText>
          )}
        </Grid>
        <Grid item xs={4}>
          <H5>Referral Source</H5>
          {!documentData.referenceOrigin && (
            <BodyText mb0>Not Available</BodyText>
          )}
          {documentData.referenceOrigin && (
            <BodyText mb0>{documentData.referenceOrigin}</BodyText>
          )}
        </Grid>
        <Grid item xs={4}>
          <H5>Phone number</H5>
          <BodyText mb0>
            {documentData?.phone ||
              documentData?.companyInfo?.phone ||
              'Not Available'}
          </BodyText>
        </Grid>
        {documentData &&
          documentData.status === 'COMPLETED' &&
          documentData.membershipEnded && (
            <Grid item xs={12}>
              <Grid container>
                <b>GST Address:</b>
                <p>&nbsp;{documentData.gstinAddress}</p>
              </Grid>
              <Grid container>
                <b>ROC Address:</b>
                &nbsp;{documentData.rocAddress}
              </Grid>
            </Grid>
          )}
        {documentData &&
          documentData.kycGracePeriodExpiryTimestamp &&
          documentData.kycGracePeriodExpiryTimestamp._seconds && (
            <>
              <Grid item xs={12} />
              <Grid item xs={4}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <DateTimePicker
                    fullWidth
                    label="KYC Grace Period Expiry"
                    inputVariant="outlined"
                    value={expiryDate}
                    onChange={handleExpiryDate}
                  />
                </MuiPickersUtilsProvider>
              </Grid>
              <Grid item xs={3}>
                <RayButton
                  disabled={loader}
                  style={{ width: '100%' }}
                  onClick={updateGracePeriod}
                >
                  {!loader ? (
                    'Update KYC Grace Period'
                  ) : (
                    <CircularProgress size={15} style={{ color: '#fff' }} />
                  )}
                </RayButton>
              </Grid>
            </>
          )}
        <Grid item xs={12}>
          <DirectorsTable
            directors={documentData.directors}
            loader={loader}
            documentId={documentData.id}
            documentData={documentData}
            areProposedDirectors={
              documentData.incorporatedDirectors &&
              documentData.incorporatedDirectors.length > 0
                ? true
                : false
            }
          />
        </Grid>
        {documentData.incorporatedDirectors &&
          documentData.incorporatedDirectors.length > 0 && (
            <Grid item xs={12}>
              <DirectorsTable
                directors={documentData.incorporatedDirectors}
                documentId={documentData.id}
                documentData={documentData}
              />
            </Grid>
          )}
        {payments?.length > 0 && (
          <Grid item xs={12}>
            <H5>Payments</H5>
            <table className="ray-table">
              <thead>
                <tr>
                  <th>Order Number</th>
                  <th>Paid By</th>
                  <th>Company</th>
                  <th>Total Price</th>
                  <th>Invoice</th>
                  <th>Checkout Email</th>
                  {/* {invoiceResponse && <th>Invoice</th>}
                {invoiceResponse?.checkoutUserEmail && <th>Checkout Email</th>} */}
                </tr>
              </thead>
              <tbody>
                {payments &&
                  payments.length > 0 &&
                  React.Children.toArray(
                    payments.map((cell, index: number) => (
                      <tr className="documents_row">
                        <td>
                          {cell.orderNumber
                            ? cell.orderNumber +
                              (cell.status === 'CANCELLED'
                                ? ' (Cancelled)'
                                : '')
                            : '--'}
                        </td>
                        <td>
                          {cell.customerFirstName
                            ? `${cell.customerFirstName} ${cell.customerLastName}`
                            : '--'}
                        </td>
                        <td>
                          {cell.customerCompany ? cell.customerCompany : '--'}
                        </td>
                        <td>
                          {cell.parent_order_total_price
                            ? stringifyPrice(+cell.parent_order_total_price)
                            : '--'}
                        </td>
                        <td>
                          {cell.invoiceInfo?.valid &&
                          cell.invoiceInfo.data.pdfURL ? (
                            <InvoiceWrapper>
                              <a
                                href={cell.invoiceInfo.data.pdfURL}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <img src={pdfIcon} alt="" />
                                {/* <span>
                                    Type : {invoiceResponse.typeOfInvoice}
                                  </span> */}
                                <span>Download Now</span>
                              </a>
                              {cell.invoiceInfo.data.GSTINValidity !== null && (
                                <p>{cell.invoiceInfo.data.GSTINValidity}</p>
                              )}
                            </InvoiceWrapper>
                          ) : (
                            '--'
                          )}
                        </td>
                        <td>
                          {cell.invoiceInfo?.valid &&
                          cell.invoiceInfo?.data?.checkoutUserEmail
                            ? cell.invoiceInfo?.data.checkoutUserEmail
                            : '--'}
                        </td>
                      </tr>
                    )),
                  )}
              </tbody>
            </table>
          </Grid>
        )}
        {subscriptions?.length > 0 && (
          <Grid item xs={12}>
            <H5>VO Subscription History</H5>
            <table className="ray-table">
              <thead>
                <tr>
                  <th>S.No</th>
                  <th>Plan Name</th>
                  <th>Tenure</th>
                  <th>Start Date</th>
                  <th>Expiry Date</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                {subscriptions &&
                  subscriptions.length > 0 &&
                  React.Children.toArray(
                    subscriptions.map((cell, index: number) => (
                      <tr className="documents_row">
                        <td>{index + 1}</td>
                        <td>{cell.selectedPlan?.name ?? '--'}</td>
                        <td>{cell.selectedPlan?.title ?? '--'}</td>
                        <td>
                          {cell.startingOn?._seconds
                            ? new Date(
                                cell.startingOn?._seconds * 1000,
                              ).toDateString()
                            : '--'}
                        </td>
                        <td>
                          {cell.expiresOn?._seconds
                            ? new Date(
                                cell.expiresOn?._seconds * 1000,
                              ).toDateString()
                            : '--'}
                        </td>
                        <td>{cell.status ?? '--'}</td>
                      </tr>
                    )),
                  )}
              </tbody>
            </table>
          </Grid>
        )}

        {/* for virtual office documents */}
        {!!documentData?.hasDashboardAccess && (
          <Grid container item xs={12}>
            <H5 style={{ width: '100%', marginBottom: '1em' }}>Documents</H5>
            {attachments &&
              attachments
                .filter(attachment =>
                  attachment.plans?.includes(
                    slugify(documentData?.selectedPlan?.name || 'existing'),
                  ),
                )
                .map((attachment: AttachmentItemType) => (
                  <Grid
                    item
                    xs={4}
                    style={{ display: 'flex', alignItems: 'center' }}
                    key={attachment.value}
                  >
                    <span>{attachment.label}</span>
                    <FiDownload
                      size={20}
                      style={{ marginLeft: '0.5em', cursor: 'pointer' }}
                      onClick={() => downloadFile(attachment)}
                    />
                  </Grid>
                ))}
          </Grid>
        )}

        {/* documentData.totalKycComplete  */}
        {(documentData.totalKycComplete === true ||
          (documentData.status === 'COMPLETED' &&
            documentData.unIncorporatedRegistration)) && (
          <Grid item xs={12}>
            <H5>Packages</H5>
            <table className="ray-table">
              <thead>
                <tr>
                  <th>Title</th>
                  <th>Tracking ID</th>
                  <th>Notes</th>
                  <th>Received At</th>
                </tr>
              </thead>
              <tbody>
                {packages &&
                  packages.length > 0 &&
                  React.Children.toArray(
                    packages.map((cell, index: number) => (
                      <tr className="documents_row">
                        <td>{cell.title ? cell.title : '--'}</td>
                        <td>{cell.trackingID ? cell.trackingID : '--'}</td>
                        <td>{cell.notes ? cell.notes : '--'}</td>
                        <td>
                          {cell.receivedAt
                            ? `${format(
                                new Date(cell.receivedAt),
                                'do MMM yyyy, hh:mm aaaa',
                              )}`
                            : '--'}
                        </td>
                      </tr>
                    )),
                  )}
              </tbody>
            </table>
          </Grid>
        )}
        {deregistration?.hasAppliedForDeregistration === true && (
          <Grid item xs={12}>
            <H5>Deregistration</H5>
            <table className="ray-table">
              <thead>
                <tr>
                  <th>Deregistration Document</th>
                  <th>Request Received At</th>
                  <th>Type of Document</th>
                </tr>
              </thead>
              <tbody>
                {deregistration && deregistration?.hasAppliedForDeregistration && (
                  <tr className="documents_row">
                    <InvoiceWrapper>
                      <a
                        href={
                          deregistration && deregistration.deregistrationDocUrl
                        }
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img src={pdfIcon} alt="" />
                        <span>Download Now</span>
                      </a>
                    </InvoiceWrapper>
                    <td>
                      {deregistration &&
                      deregistration.deregistrationRequestCreatedAt
                        ? `${format(
                            new Date(
                              deregistration.deregistrationRequestCreatedAt,
                            ),
                            'do MMM yyyy, hh:mm aaaa',
                          )}`
                        : '--'}
                    </td>
                    <td>
                      {deregistration && deregistration.deregistrationDocType
                        ? `${deregistration.deregistrationDocType}`
                        : '--'}
                    </td>
                    <td>
                      <RayButton
                        compact
                        buttonType="secondary"
                        style={{ marginBottom: '-3px' }}
                        onClick={() => handleApprove(approve)}
                        disabled={documentData.expired}
                      >
                        Approve
                      </RayButton>
                    </td>
                    <RayButton
                      compact
                      buttonType="secondary"
                      style={{ marginBottom: '-3px' }}
                      danger={true}
                      onClick={() => handleReject(reject)}
                      disabled={documentData.expired}
                    >
                      Reject
                    </RayButton>
                  </tr>
                )}
              </tbody>
            </table>
          </Grid>
        )}
        {(documentData.totalKycComplete === true ||
          (documentData.status === 'COMPLETED' &&
            documentData.unIncorporatedRegistration)) && (
          <Grid item xs={3}>
            <RayButton
              disabled={loader}
              style={{ width: '100%' }}
              onClick={() => toggleDetailModal(true)}
            >
              {!loader ? (
                'Add Package'
              ) : (
                <CircularProgress size={15} style={{ color: '#fff' }} />
              )}
            </RayButton>
          </Grid>
        )}
      </Grid>
      <ModalDialog
        isOpen={detailModal}
        showModal={toggleDetailModal}
        fullWidth
        maxWidth="sm"
        title="Add Package"
      >
        <Grid container justify="center" spacing={2}>
          <Grid item xs={9}>
            <RayInput
              fullWidth
              id="title"
              name="package-title"
              label="Package Title"
              placeholder="Enter the title"
              type="text"
              onChange={e => setPackageTitle(e.target.value)}
            />
          </Grid>
          <Grid item xs={9}>
            <RayInput
              fullWidth
              id="notes"
              name="package-notes"
              label="Package Note"
              placeholder="Enter a note"
              type="text"
              onChange={e => setPackageNotes(e.target.value)}
            />
          </Grid>
          <Grid item xs={9}>
            <RayInput
              fullWidth
              id="trackingID"
              name="package-trackingID"
              label="Package Tracking ID"
              placeholder="Enter the Tracking ID"
              type="text"
              onChange={e => setPackageTrackingID(e.target.value)}
            />
          </Grid>
          <Grid item xs={9}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <DateTimePicker
                fullWidth
                label="Received At"
                inputVariant="outlined"
                value={packageDate}
                onChange={handleDateChange}
              />
            </MuiPickersUtilsProvider>
          </Grid>
          <Grid item xs={12}>
            <Grid container justify="space-between">
              <RayButton
                buttonType="secondary"
                onClick={() => toggleDetailModal(false)}
              >
                Cancel
              </RayButton>
              <RayButton buttonType="primary" onClick={sendPackage}>
                Send Notification
              </RayButton>
            </Grid>
          </Grid>
        </Grid>
      </ModalDialog>
      <UpdateDocumentStatus
        documentData={documentData}
        showModal={changeStatusModal}
        toggleOpenModal={toggleChangeStatusModal}
      />
      <ChangeBuildingModal
        documentData={documentData}
        isOpen={openChangeBuildingModal}
        setChangeBuildingState={setOpenChangeBuildingModal}
      />
      <ChangeCompanyNameModal
        documentData={documentData}
        isOpen={openChangeCompanyNameModal}
        setChangeCompanyNameState={setOpenChangeCompanyNameModal}
      />
    </>
  );
}

export default Details;
